import React, { useContext } from "react"
import { Link } from "gatsby"
import Template from "../../components/template"
import "../../styles/blog/blogpost.css"
import { NOuLogoContent } from "../../content/blog/nOu-logo"
import { SettingsContext } from "../../contexts/settings"

const NOuLogoPage = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = NOuLogoContent[settings.language]
    return (
        <Template currentPage="blog">
            <section>
            <div>
                <h1>
                    <Link to="/blog#nOu-logo" class="back-arrow">{content.arrow}</Link>
                    {content.headline}
                </h1>
                <p>{content.date}</p>
                <img class="nou-logo" src="/images/blog/nOu_Logo.png" alt="neues Logo von Nocturnal Untrest"/>
                <p>
                    {content.first}
                </p>
                <p>
                    {content.second}
                </p>
                <p className="end-of-site">
                    {content.third}
                </p>
            </div>    
            </section>
        </Template >
    )
}

export default NOuLogoPage