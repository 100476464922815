const NOuLogoContent = {
    german: {
        date: "16. Dezember 2020",
        arrow: "BLOG << ",
        headline: "nOu Logo",
        first: "Wir haben endlich ein Logo, dank dessen Nocturnal Unrest super schnell erkennbar ist. Schon in unserer Grafik drückt sich nun die Grundidee hinter nOu aus: interessant wird es da, wo die Grenzen zwischen Tag und Nacht verwischen. Daher steht im Zentrum des Logos ein Farbverlauf, der die Buchstaben unseres Namens n – o – u miteinander verbindet. Nicht hell und dunkel, sondern Dämmerung und Morgengrauen rücken ins Zentrum.",
        second: "Ohne die Hilfe unserer fantastischen Grafikdesignerin Susi Sauer gäbe es dieses tolle Logo nicht. In langen Sessions hat sie sich unsere Ideen, Konzepte und Prozesse angehört, mit verschiedenen Entwürfen gespielt und vor allem designed, was das Zeug hält.",
        third: "Uns klappte die Kinnlade runter, als wir den finalen Entwurf sehen durften und wir sind so stolz, dass Noctunal Unrest nun nicht nur in Wort und Tat, sondern auch in Bild und Visual so unresty ist! Danke, Susi!"
    },
    english: {
        date: "December 16, 2020",
        arrow: "BLOG << ",
        headline: "nOu Logo",
        first: "We finally have a logo that makes Nocturnal Unrest super easy to recognise. Thanks to this visualisation, the basic idea behind nOu is already expressed in our graphics: it gets interesting where the boundaries between day and night blur. Therefore, at the centre of the logo is a colour gradient that connects the letters of our name n – o – u. Not light and dark, but twilight and dawn come into focus.",
        second: "Were it not for the help and endless amount of work done by our fantastic graphic designer Susi Sauer, this great logo would not exist. In long sessions, she listened to our ideas, concepts and processes, played with different designs and, above all, designed the heck out of it.",
        third: "Our jaws dropped when we got to see the final graphics and we are so proud that Noctunal Unrest now looks as unresty as it should – not only in word and deed, but also in image and visuals! Thank you so much, Susi!"
    }

}
export {NOuLogoContent}